import { Cmp, Ctrl, state } from ":mods";
import { $course_data } from "../store";

export function TopCourse(props: Ctrl.Nav.model.TopNavJSXItemProps) {
  const default_data = {
    course_name: "course name",
    percentage_done: 0,
  };
  const $data = state.create(default_data);

  $course_data.on((event, { value }) => {
    if (!value) {
      $data.set(default_data);
      return;
    }
    $data.set((s) => ({
      ...s,
      percentage_done: value.percentage,
      course_name: value.name,
    }));
  });
  return (
    <section
      class={`relative flex flex-row items-center !w-full h-full space-x-2 ${
        !$course_data.value ? "invisible" : "visible"
      }`}
    >
      <Ctrl.Nav.Element.Divider />
      <span class="capitalize whitespace-nowrap text-0.7rem">{$data.value.course_name}</span>
      <Ctrl.Nav.Element.Divider />
      <section class="flex flex-col w-full items-start overflow-hidden">
        <span class="capitalize whitespace-nowrap">{$data.value.percentage_done}% of the course completed</span>
        <div class="w-full h-10px bg-white rounded-lg overflow-hidden">
          <div
            class="h-full bg-red transition-all duration-1000 ease-in-out"
            id="progress-bar"
            style={`width: ${$data.value.percentage_done}%;`}
          ></div>
        </div>
      </section>
    </section>
  );
}
